import { includes, find, reduce, get, map, cloneDeep } from "lodash-es";
import { SubscriptionStatus } from "../../constants/user";
import { IWalletUpdated } from "../../types/shopWallet";
import { Time } from "../../models/time";
import { PushNotification } from "../pushNotification";
import {
  SystemMaintenanceError,
  TenantMismatchError,
} from "../../constants/apollo";
import { Currency } from "../../constants/shopWallet";
import {
  CoinType,
  FreeCoins,
  FullTransaction,
  PermissionGroup,
  TransactionActionType,
  UserSubscription,
} from "../../gql/graphql";
import { OnmoStorage } from "../onmoStorage";
import { CoinWallet, User } from "../../gqlV2/graphql";

export const isAdmin = (permissions: PermissionGroup[]) => {
  return (
    includes(permissions, PermissionGroup.Editor) ||
    includes(permissions, PermissionGroup.Publisher)
  );
};

export const isQaRole = (permissions: PermissionGroup[]) => {
  return includes(permissions, PermissionGroup.Qa);
};

export const getWalletsFromFreeCoins = (user: User, freeCoins: FreeCoins) => {
  switch (freeCoins?.status) {
    case "MAX_BALANCE_EXCEED":
      throw new Error("Maximum coin balance allowed to claim free coins");
    case "USER_IN_CASH_REGION":
      throw new Error("Get free coin cannot be used for cash region users");
    case "USE_IN_NEXT_AVAILABLE_TIME": {
      const nextTimeGetCoin = new Date(freeCoins?.nextAvailableTime);
      // SUBSTRACT ONE DAY FROM START NEXT TIME
      nextTimeGetCoin.setDate(nextTimeGetCoin.getDate() - 1);
      OnmoStorage.setTimeClaimCoin(user.id, nextTimeGetCoin.getTime());
      throw new Error(
        `You can get free coin on ${Time.formatDateGetCoin(
          freeCoins?.nextAvailableTime
        )}`
      );
    }
    case "FAILED":
      throw new Error("Failed to award the coins");
    case "SUCCESS":
      return true;
  }
};

export const isEnableNotification = (user?: User | null) => {
  return !!user?.pushSubscription && user?.pushSubscription !== "null";
};

export const togglePushSubscription = async (user?: User | null) => {
  if (isEnableNotification(user)) {
    return PushNotification.unsubscribe();
  } else {
    return PushNotification.subscribe();
  }
};

export const isSubscribedB2b = (subscription?: UserSubscription | null) => {
  return subscription?.status === SubscriptionStatus.subscribed;
};

export const isUnsubscribedB2b = (subscription: UserSubscription | null) => {
  return (
    subscription?.status === SubscriptionStatus.unsubscribed ||
    subscription === null
  );
};

export const isPendingB2b = (subscription: UserSubscription) => {
  return subscription?.status === SubscriptionStatus.pending;
};

export const convertEventWalletToWallet = (
  eventWallet: IWalletUpdated,
  wallets: CoinWallet[] | null
) => {
  if (eventWallet.currency === Currency.Xxx) {
    if (wallets) {
      return map(cloneDeep(wallets), (w) => {
        if (w.itemType === CoinType.Basic) {
          w.balance = Math.abs(eventWallet.balance);
        }
        return w;
      });
    }
    return [
      {
        balance: Math.abs(eventWallet.balance),
        itemType: CoinType.Basic,
        userId: "",
        __typename: "CoinWallet",
      } as CoinWallet,
    ];
  }
  return null;
};

export const getFormatDateLanguage = (user: User) => {
  if (user.language.includes("en")) {
    return "en-GB";
  }
  return user.language;
};

export const findTransactionCoinsReceived = (
  unseenRewardTransactions: FullTransaction[]
) => {
  return find(
    unseenRewardTransactions,
    (u) => u.transactionType === TransactionActionType.SubscriptionCoinsReceived
  );
};

export const findTransactionBonusCoinsReceived = (
  unseenRewardTransactions: FullTransaction[]
) => {
  return find(
    unseenRewardTransactions,
    (u) => u.transactionType === TransactionActionType.BonusCoinsReceived
  );
};

export const findLastSeenTransaction = (
  unseenRewardTransactions: FullTransaction[]
) => {
  return reduce(unseenRewardTransactions, (a, b) => {
    return new Date(a.createdAt) > new Date(b.createdAt) ? a : b;
  });
};

// TODO: move out
export const isErrorMaintenance = (e: Error | unknown) => {
  const error = get(e, "networkError.result.errors[0].extensions.code");
  if (error === SystemMaintenanceError) {
    return true;
  }
  return false;
};

export const isErrorTenant = (e: Error | unknown) => {
  const errorCode = get(e, "networkError.result.errors[0].extensions.code");
  if (errorCode === TenantMismatchError) {
    return true;
  }
  return false;
};

export const isAccountDisable = (message: string) => {
  return message === "User is disabled." || message === "User is not enabled";
};

export const isShowMyRank = (xp: number) => {
  if (!xp) return false;
  return xp > 0 && xp < 11;
};
