import { QueryOptions } from "@apollo/client";
import { gamesGQL } from "../../queries/games";
import apollo from "../../../clients/apollo";
import { ListGamesInput, Query } from "../../../gqlV2/graphql";

const apolloClient = apollo.getInstance();

export const queryGames = async (
  options: Partial<QueryOptions> = {},
  params: ListGamesInput
): Promise<Query["games"]["items"]> => {
  const res = await apolloClient.query({
    ...options,
    context: { apiName: "v2" },
    query: gamesGQL,
    variables: {
      input: params,
    },
  });

  return res.data.games.items;
};
