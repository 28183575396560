export enum SubscriptionOperations {
  WalletUpdated = "WalletUpdated",
  FriendRequestBattle = "OnFriendRequestBattle",
  FiendRequestCreated = "OnFiendRequestCreated",
  FiendRequestAccepted = "OnFiendRequestAccepted",
  FriendDeleted = "OnFriendDeleted",
  NotificationCreated = "OnNotificationCreated",
}

export enum SubscriptionStatus {
  Success = "success",
  Error = "error",
  Subscribed = "subscribed",
}

export const PING_DELAY = 60000;

export const SystemMaintenanceError = "SYSTEM_MAINTENANCE_ERROR";
export const TenantMismatchError = "TENANT_MISMATCH";
export const TenantErrorMessage = "User does not belong to the Tenant";
