import { ILeaderboard } from "../../types/tournament";
import { compact, filter, orderBy, sortBy } from "lodash-es";
import { RankOrder, TournamentInfo } from "../../gql/graphql";
import { User } from "../../gqlV2/graphql";
import { TournamentCategories } from "../../constants/tournaments";

export const getMyGemReceive = (tournament?: TournamentInfo) => {
  return tournament?.calculatedPrizePool?.[tournament.myRank - 1] || 0;
};

export const getLeaderboardTournament = (
  tournament: TournamentInfo,
  user?: User | null
) => {
  if (tournament.leaderboards.length === 0) return [];

  //add rank to leaderboards
  const newLb = tournament.leaderboards.map(
    (leaderboard: ILeaderboard, index: number) => ({
      ...leaderboard,
      rank: index + 1,
    })
  );
  const myUserInLb = newLb.find((player) => player.userId === user?.id);
  const remainingUserLb = newLb.filter((player) => player.userId !== user?.id);

  if (myUserInLb) {
    // return new leaderboard with my rank on first list
    return [myUserInLb, ...remainingUserLb];
  } else if (tournament.userBestScore !== -1 && tournament.userBestScore) {
    const myRankOutTop10Leaderboard = {
      endedAt: "",
      id: "",
      rank: tournament.myRank,
      score: tournament.userBestScore,
      startedAt: tournament.startedAt,
      status: tournament.status,
      tournamentId: tournament.id,
      user: {
        xp: user?.xp ?? 0,
        banner: user?.banner ?? "",
        avatar: user?.avatar ?? "",
        username: user?.username ?? "",
        id: user?.id ?? "",
      },
      userId: user?.id ?? "",
    };

    // Leaderboard only return 10 players so we need to add my rank if it's not in the top 10
    return compact([myRankOutTop10Leaderboard, ...remainingUserLb]);
  }

  // return original leaderboard
  return newLb;
};

export const getPlayerRank = (
  leaderboards: ILeaderboard[],
  playerId: string
) => {
  const index = leaderboards?.findIndex((p) => p.user.id === playerId);
  return index + 1;
};

export const updateLbByScore = (
  leaderboards: ILeaderboard[],
  nbScore: number,
  user: User,
  rankOrder: string | undefined
) => {
  const leaderboardsWithOutScore = leaderboards.filter(
    (player) => player.user.id !== user.id && player.score <= 0
  );
  const myLb = {
    score: nbScore <= 0 ? 0 : nbScore,
    user: { id: user.id, username: user.username, avatar: user.avatar },
  } as ILeaderboard;

  const newLb = leaderboards
    .reduce((acc: ILeaderboard[], cur: ILeaderboard) => {
      if (cur.user.id !== user.id && cur.score > 0) acc.push(cur);
      return acc;
    }, [])
    .concat(myLb);

  const sortLb = sortBy(newLb, "score", (a) => a.user.id !== user.id);

  if (rankOrder === RankOrder.Asc) {
    sortLb.concat(leaderboardsWithOutScore);
  } else {
    sortLb.reverse().concat(leaderboardsWithOutScore);
  }

  const playerIndex = getPlayerRank(sortLb, user.id);

  if (playerIndex === 10) return playerIndex + 1;
  return playerIndex;
};

export const getListTournamentSorted = (
  tournaments: TournamentInfo[] | null
) => {
  if (!tournaments) return [];
  const availableTournament = filter(tournaments, (t) => {
    return (
      new Date(t.expiredAt).getTime() > new Date().getTime() &&
      t.status === "OPEN"
    );
  });

  const orderByFeature = orderBy(
    availableTournament,
    (t) => t.tournamentType.includes(TournamentCategories.Featured),
    ["desc"]
  );

  return orderByFeature;
};
