import { createUseStyles } from "react-jss";
import { ZIndex } from "../constants/zIndex";
import { TIME_TO_SLIDE_PAGE } from "../constants/slider";

export const useStyles = createUseStyles({
  contentBlock: {
    height: "100%",
    padding: 15,
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
  backdrop: {
    zIndex: ZIndex.dialog + 1,
  },
  pageContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    animation: `$forwardsAnimate ${TIME_TO_SLIDE_PAGE}s ease-out`,
  },
  pageContainerBack: {
    animation: `$backAnimate ${TIME_TO_SLIDE_PAGE}s ease-out`,
  },
  "@keyframes forwardsAnimate": {
    "0%": {
      transform: "translateX(100%)",
    },
    "100%": {
      transform: "translateX(0%)",
    },
  },
  "@keyframes backAnimate": {
    "0%": {
      transform: "translateX(-100%)",
    },
    "100%": {
      transform: "translateX(0%)",
    },
  },
});
