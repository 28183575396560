import { ONMO_CONFIG } from "./onmo";

export enum UserPermissionGroups {
  EDITOR = "EDITOR",
  PUBLISHER = "PUBLISHER",
  BANKER = "BANKER",
  SUPPORT = "SUPPORT",
  QA = "QA",
}

export enum UserStatsType {
  WinCash = "WinCash",
  WinCoin = "WinCoin",
  Battle = "Battle",
  Tournament = "Tournament",
  Moment = "Moment",
  Friend = "Friend",
  BeatIt = "BeatIt",
  SoloChallenges = "SoloChallenges",
}

export enum SubscriptionStatus {
  pending = "pending",
  subscribed = "subscribed",
  unsubscribed = "unsubscribed",
}

export const AVATAR_ENDPOINT = `${ONMO_CONFIG?.cms?.cdnPath}assets/user/avatar/`;

export const ONBOARDING = {
  hasPlayedFirstGame: true,
  hasPlayedFirstSocialGame: true,
  hasSeenNotificationPopup: true,
  hasSeenHomepage: true,
  lastSeenTransactionId: "",
};

export enum TransactionStatus {
  SubscriptionCoinsReceived = "subscription_coins_received",
  BonusCoinsReceived = "bonus_coins_received",
}

export enum RankGem {
  Unranked = "Unranked",
  Beginner = "Beginner",
  Intermediate = "Intermediate",
  Advanced = "Advanced",
  Expert = "Expert",
  Master = "Master",
  Champion = "Champion",
  GrandChampion = "Grand Champion",
}

export enum RankColor {
  Unranked = "#B2B2B2",
  Beginner = "linear-gradient(270deg, #834402 0%, #D9904D 100%)",
  Intermediate = "linear-gradient(270deg, #8E9EB8 0%, #C8D4E9 100%)",
  Advanced = "linear-gradient(270deg, #DE900B 0%, #F1BB06 100%)",
  Expert = "linear-gradient(270deg, #FFA89C 0%, #ED0000 100%)",
  Master = "linear-gradient(270deg, #76BCFE 0%, #3771FF 100%)",
  Champion = "linear-gradient(270deg, #C289FF 0%, #474DFF 100%)",
  GrandChampion = "linear-gradient(270deg, #4844FF 0%, #E544FF 35%, #FFEA31 72%, #44FFC7 100%)",
}

export enum LevelValue {
  Unranked = 0,
  Beginner = 3,
  Intermediate = 20,
  Advanced = 50,
  Expert = 200,
  Master = 500,
  Champion = 1000,
  GrandChampion = 2000,
}

export enum UserStatus {
  Online = "Online",
  Offline = "Offline",
  Away = "Away",
}

export const TextStyleByLanguage: {
  [key: string]: "default" | "small" | "medium";
} = {
  am: "small",
  my: "small",
};

export const NB_LIMIT_CHARACTER_USERNAME = 16;

export const NB_MAX_USERNAME = 12;

export const NB_SHOW_CHARACTER = 10;

export const getHeight = (isGlobalLeaderboard: boolean) => {
  if (isGlobalLeaderboard) {
    return 190;
  }
  return 120;
};
